import { graphql, Link, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";

const IndexPage: React.FC<PageProps<Queries.IndexPageQuery>> = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo title="Home" />
      <div className="prose max-w-full pt-12 pb-4">
        <h1 className="md:text-6xl">{data.site?.siteMetadata?.title}</h1>
        <p>{data.site?.siteMetadata?.description}</p>
      </div>
      <div className="flex items-end justify-between border-b-2 pt-12 pb-4">
        <h2 className="text-2xl font-bold">Latest</h2>
        <Link to="/post">
          <span className="textlink text-gray-500">More post →</span>
        </Link>
      </div>
      <ul className="mb-8 list-none">
        {data.allContentfulPost.nodes.map((props) => (
          <li className="pt-6 pb-4" key={props.slug}>
            <Link
              to={"/post/" + props.slug}
              key={props.slug}
              className="group "
            >
              <h2 className="mb-2 text-2xl font-bold transition-all  group-hover:underline">
                {props.title}
              </h2>
              <p className="text-gray-500">
                <time>{props.createdAt}</time>
                {"・"}
                <span>
                  {props.content?.childMarkdownRemark?.timeToRead} min read
                </span>
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulPost(limit: 5, sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        slug
        createdAt(fromNow: true)
        content {
          childMarkdownRemark {
            timeToRead
          }
        }
      }
    }
  }

  # This fragment is neccessary to generate interface of @/components/PostListItem
  fragment PostListItemProps on ContentfulPost {
    title
    slug
    createdAt(formatString: "YYYY-MM-DD")
    content {
      childMarkdownRemark {
        timeToRead
      }
    }
  }
`;

export default IndexPage;
